<template>
    <table class="dashboard-table">
        <thead role="rowgroup sticky-row">
            <tr role="row">
                <th class="sticky-column child-1" scope="col"><button class="tab ml-4" :class="{active: defaultView}" @click="$emit('setDefaultView', true)">Saldi</button><button class="tab" :class="{active: !defaultView}" @click="$emit('setDefaultView', false)">impegni</button><div>Banca</div></th>
                <th class="sticky-column child-2" scope="col"><div>Fido</div></th>
                <th class="sticky-column child-3" scope="col"><div>Conto Anticipi</div></th>
                <th class="sticky-column child-4" scope="col"><div>Saldo contabile</div></th>
                <th class="sticky-column child-5" scope="col"><div>Saldo disponibile</div></th>
                <th class="child-6" v-for="(todayDate, i) in 91" :key="`date_+${i}`" :class="{today: i===0}">
                    <label v-if="i === 0" class="tag today"></label>
                    <label @click="scrollWeek(7)" v-if="i === 7" class="tag one-week"></label>
                    <label @click="scrollWeek(14)" v-if="i === 14" class="tag two-weeks"></label>
                    <label @click="scrollWeek(21)" v-if="i === 21" class="tag three-weeks"></label>
                    <label @click="scrollWeek(28)" v-if="i === 28" class="tag four-weeks"></label>
                    <label @click="scrollWeek(35)" v-if="i === 35" class="tag five-weeks"></label>
                    <label @click="scrollWeek(42)" v-if="i === 42" class="tag six-weeks"></label>
                    <label @click="scrollWeek(49)" v-if="i === 49" class="tag seven-weeks"></label>
                    <label @click="scrollWeek(56)" v-if="i === 56" class="tag eight-weeks"></label>
                    <label @click="scrollWeek(63)" v-if="i === 63" class="tag nine-weeks"></label>
                    <label @click="scrollWeek(70)" v-if="i === 70" class="tag ten-weeks"></label>
                    <label @click="scrollWeek(77)" v-if="i === 77" class="tag eleven-weeks"></label>
                    <label @click="scrollWeek(84)" v-if="i === 84" class="tag twelve-weeks"></label>
                    <div>
                        {{ getDate(i) }}
                    </div>
                </th>
            </tr>
        </thead>
        <TableBody v-for="(item, i) in items" :obj="item" :selected="isSelectedRow(i)" @updateSelectedRow="updateSelectedRow(i)" :class="[{clickable: (item.type === 'bank' || item.type === 'cash')}, {'sticky-row': items.length - i < 6}, items.length - i < 6 ? `sticky-row-${items.length - i}` : ``]" :key="`${item.name}_${i}`" :id="item.iban || 'cash'" :ref="item.iban ? item.iban : 'cash'"/>
    </table>
</template>

<script>
import TableBody from '@/views/components/Table/TableBody.vue';

export default {
  props: {
    items: Array,
    todayDate: String,
    defaultView: Boolean,
    scroll: Function,
    scrollX: Function,
  },
  components: {
    TableBody,
  },
  data () {
    return {
      selectedRowIndex: null,
    };
  },
  methods: {
    getDate (i) {
      const x = new Date();
      x.setDate(x.getDate(this.todayDate) + i);
      return x.getDate() + '/' + (x.getMonth() + 1) + '/' + x.getFullYear().toString().slice(-2);
    },
    updateSelectedRow (rowIndex) {
      if (this.items[rowIndex].type) {
        this.selectedRowIndex = (this.selectedRowIndex !== rowIndex) ? rowIndex : null;
        if (this.items[rowIndex].type === 'bank') {
          this.$emit('scroll', this.selectedRowIndex);
        }
      }
    },
    isSelectedRow (rowIndex) {
      return (this.selectedRowIndex === rowIndex);
    },
    scrollWeek (colIndex) {
      this.$emit('scrollX', colIndex);
    },
  },
};
</script>
