<template>
    <tbody>
        <!-- Popover -->
        <b-popover v-if="obj.type === 'bank'" :target="`${obj.bank}_${obj.iban}`" placement="right" triggers="hover focus">
            <div class="popover" :class="`bank-variant-${obj.colour}`">
              <div class="d-flex mb-2">
                  <div class="img-wrapper mr-2">
                    <img :src="obj.isCash ? cashLogoDefault : (obj.logo ? obj.logo : bankLogoDefault)" class="bank-logo mr-2">
                  </div>
                  <div class="name">{{obj.alias}}</div>
              </div>
              <div class="d-flex">
                  <div class="pill mr-2" :class="`text-bank-variant-${obj.colour}`">IBAN</div>
                  <div class="description">{{obj.iban}}</div>
              </div>
          </div>
        </b-popover>

        <!-- Table -->
        <tr :class="(showDetails ? rowColour : rowColourLight)" @click="showDetails = !showDetails; $emit('updateSelectedRow'); $router.replace({ query: { bankId: obj.id } }).catch(() => {})">
            <td class="sticky-column child-1 row-title" :class="[(showDetails ? rowColour : rowColourLight), nameColour, {'font-weight-bolder':  (obj.type === 'bank' || obj.type === 'cash')}]" :id="`${obj.bank}_${obj.iban}`" @mouseover="hovered = true" @mouseleave="hovered = false" data-toggle="popover" data-trigger="hover">
              <img v-if="obj.type === 'bank' || obj.type === 'cash'" :src="obj.type === 'cash' ? cashLogoDefault : (obj.logo ? obj.logo : bankLogoDefault)" class="bank-logo m-0 p-0">
              {{ obj.alias ? obj.alias : obj.name }}
            </td>
            <CellContent class="sticky-column child-2" :class="(showDetails ? rowColour : rowColourLight)" :value="obj.fido" />
            <CellContent class="sticky-column child-3" :class="(showDetails ? rowColour : rowColourLight)" :value="obj.conto_anticipi" />
            <CellContent class="sticky-column child-4" :class="(showDetails ? rowColour : rowColourLight)" :value="obj.saldo_contabile" />
            <CellContent class="sticky-column child-5" :class="(showDetails ? rowColour : rowColourLight)" :value="obj.saldo_disponibile" />
            <CellContent :class="[{today: (item < 0 ? false : i===0)},`bank-variant-${rowColour}`]" v-for="(item, i) in obj.saldi" :key="`saldi_${i}_${obj.name}`" :value="item" />
        </tr>
          <tr v-if="showDetails && (hasAllDetails || hasSomeDetails)" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Scadenzario passivo</td>
              <CellContent v-for="(item, i) in obj.scadenzario_passivo" :key="`scadenzario_passivo_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)"/>
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Stipendi</td>
              <CellContent v-for="(item, i) in obj.stipendi" :key="`stipendi_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Altri bonifici</td>
              <CellContent v-for="(item, i) in obj.altri_bonifici" :key="`bonifici_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Girofondi</td>
              <CellContent v-for="(item, i) in obj.girofondi" :key="`girofondi_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Ri.Ba e titoli di credito</td>
              <CellContent v-for="(item, i) in obj.riba_titoli" :key="`riba_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)"/>
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Finanziamenti</td>
              <CellContent v-for="(item, i) in obj.finanziamenti" :key="`finanziamenti_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Anticipi</td>
              <CellContent v-for="(item, i) in obj.anticipi" :key="`anticipi_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Pagamenti fiscali</td>
              <CellContent v-for="(item, i) in obj.pagamenti_fiscali" :key="`pagamenti_fiscali_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && (hasAllDetails || hasSomeDetails)" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Versamenti/prelievi</td>
              <CellContent v-for="(item, i) in obj.versamenti_prelievi" :key="`versamenti_prelievi_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && (hasAllDetails || hasSomeDetails)" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Competenze e oneri bancari</td>
              <CellContent v-for="(item, i) in obj.competenze_oneri" :key="`competenze_oneri_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && (hasAllDetails || hasSomeDetails)" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">{{ hasSomeDetails ? 'Incassi/Pagamenti' : 'Accrediti/addebiti' }}</td>
              <CellContent v-for="(item, i) in obj.addebiti_accrediti" :key="`addebiti_accrediti_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
          <tr v-if="showDetails && hasAllDetails" :class="rowColourLight">
              <td class="sticky-column child-1 row-description" :class="rowColourLight" colspan="5">Saldo</td>
              <CellContent v-for="(item, i) in obj.saldi" :key="`stipendi_${i}_${obj.name}`" :class="{today: (item < 0 ? false : i===0)}" :value="item" @click.native="redirect(i)" />
          </tr>
    </tbody>
</template>

<script>
import CellContent from '@/views/components/Table/CellContent.vue';
import { BPopover } from 'bootstrap-vue';
import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

export default {
  props: {
    obj: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CellContent,
    'b-popover': BPopover,
  },
  data  () {
    return {
      nameColour: this.obj.colour ? `text-bank-variant-${this.obj.colour}` : '$primary',
      rowColour: this.obj.colour ? `bank-variant-${this.obj.colour}-light` : 'white',
      rowColourLight: this.obj.colour ? `bank-variant-${this.obj.colour}-lighter` : 'white',
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
    };
  },
  computed: {
    hasAllDetails () {
      return this.obj.type === 'bank';
    },
    hasSomeDetails () {
      return this.obj.type === 'cash';
    },
    showDetails: {
      get () {
        return this.selected;
      },
      set (newValue) {
        return newValue;
      },
    },
  },
  mounted () {
    if (this.obj.id === this.$route.query.bankId) {
      this.$emit('updateSelectedRow');
    }
  },
  methods: {
    isNegativeAmount (v) {
      return v < 0;
    },
    getDate (i) {
      const x = new Date();
      x.setDate(x.getDate(this.todayDate) + i);
      return x.getDate() + '/' + (x.getMonth() + 1) + '/' + x.getFullYear().toString().slice(-2);
    },
    redirect (i) {
      // console.log(this.getDate(i));
      // console.log(this.obj.name);

      let x = new Date();
      x.setDate(x.getDate(this.todayDate) + i);
      x = x.getFullYear().toString() + '-' + ('0' + (x.getMonth() + 1)).slice(-2) + '-' + ('0' + x.getDate()).slice(-2);
      const query = i === 0 ? { bankId: this.obj.id, endDate: x } : { bankId: this.obj.id, startDate: x, endDate: x };
      this.$router.push({ name: 'movements', query: query });
    },
  },
};
</script>

<style lang="scss">

.popover {
  border: none;
  padding: 10px;
  width: fit-content;
  min-width: 300px;
  &::after {
    content: '';
  }
  .arrow {
    display: none;
  }
  &::before {
    content: '';
    position: absolute;
    left: -5px;
    top: 10px;
    z-index: -1;
    height: 15px;
    width: 15px;
    margin: 0;
    transform: rotate(45deg);
    padding: 0;
    background: inherit;
    & > * {
      display: none;
    }
  }
  .name {
      color: white;
      font-weight: 700;
      font-size: 16px;
      width: fit-content;
      text-overflow: ellipsis;
  }
  .description {
      color: white;
      font-weight: 500;
      font-size: 13px;
  }
  .img-wrapper {
      display: block;
      height: 25px;
      min-width: 25px;
      width: 25px;
      border-radius: 50%;
      background: $fin-white;
  }
  .pill {
    background: $fin-white;
    border-radius: 5px;
    padding: 0px 4px;
    font-weight: 600;
    font-size: 13px;
    opacity: 0.8;
  }
}
</style>
